import React from 'react'
import * as Sentry from '@sentry/react'

import Button from '@components/Button'
import Card from '@components/Card'

export const ErrorBoundary: Sentry.FallbackRender = ({ resetError }) => {
  return (
    <div className="w-full flex justify-center items-center h-screen bg-gray-50">
      <Card className="flex flex-col items-center text-center gap-3 max-w-2xl w-full">
        <a href="/">
          <img src="/logo.svg" className="w-24 h-24" />
        </a>
        <h3>An error has occured</h3>
        <p>
          Try refreshing the page. If the error persists, please{' '}
          <span className="underline text-brand_primary cursor-pointer" onClick={() => window.Intercom('show')}>
            contact support
          </span>
          .
        </p>

        <div className="flex justify-end w-full">
          <Button onClick={resetError} buttonType="button">
            Click here to get back!
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default ErrorBoundary
